import couponItem from '../../components/getCoupon.vue' //优惠券
export default {
    name: 'coupon',
    components: {
        couponItem
    },
    data() {
        return {
            data: null,
            is_one: true
        }
    },
    created() {
        localStorage.removeItem('login_data');
        this.getInfo();
    },
    methods: {
        toHome() {
            let _this = this;
            _this.$router.replace({
                name: 'home',
                query: _this.$route.query
            })
        },
        coupon_receive() {
            let _this = this;
            (this.$route.query.single ? this.$UtilsApi.coupon_receive_single(this.$route.query.sn) : this.$UtilsApi.coupon_receive(this.$route.query.sn)).then(res => {
                if (res.data.code == 0) {
                    this.$notify({ type: 'success', message: '领取成功', duration: 2000 });
                    this.getInfo();
                    this.$dialog.alert({
                        title: '跳转提示',
                        message: '领券成功，即将去到首页',
                    }).then(() => {
                        _this.toHome();
                    });
                } else {
                    if (res.data.code == 401) {
                        let data = {
                            name: 'coupon',
                            sn: this.$route.query.sn
                        };
                        this.$route.query.single ? data.single = this.$route.query.single : '';
                        localStorage.setItem('login_data', JSON.stringify(data));
                        window.location.replace('//paradise.h5.airland100.com/#/login');
                        return
                    };
                    this.$dialog.alert({
                        title: res.data.msg ? res.data.msg : res.data.message,
                        message: '即将去到首页',
                    }).then(() => {
                        _this.toHome();
                    });
                }
            });
        },
        getInfo() {
            (this.$route.query.single ? this.$UtilsApi.coupon_info_single({}, this.$route.query.sn) : this.$UtilsApi.coupon_info({}, this.$route.query.sn)).then(res => {
                if (res.data.code == 0) {
                    if (res.data.data.member_token == localStorage.getItem('user_token') && this.$route.query.single || !this.$route.query.single) {
                        res.data.data.to_h = '去使用';
                    } else {
                        res.data.data.to_h = '去首页';
                    }
                    this.data = res.data.data;
                    console.log(this.data)
                    localStorage.setItem('mall_id', res.data.data.mall_id);
                } else {
                    this.$notify({ type: 'warning', message: res.data.msg, duration: 2000 });
                    setTimeout(() => {
                        this.toHome();
                    }, 500);
                }
            })
        }
    },
}