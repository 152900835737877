import { render, staticRenderFns } from "./coupon.vue?vue&type=template&id=2cb3671c&scoped=true&"
import script from "./coupon.js?vue&type=script&lang=js&"
export * from "./coupon.js?vue&type=script&lang=js&"
import style0 from "./coupon.css?vue&type=style&index=0&id=2cb3671c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cb3671c",
  null
  
)

export default component.exports