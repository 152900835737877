<template>
  <div class="main">
    <div class="item_box" :class="{ item_box_c: type != 1 }">
      <div class="item_logo">
        <img src="../assets/icon_kapiao.png" alt="" v-if="type == 1" />
        <img src="../assets/icon_cipiao.png" alt="" v-else />
      </div>
      <div class="item_box_r">
        <div class="r_title">{{ title }}</div>
        <div class="num_box" v-if="type == 1">
          ￥<span>{{ money }}</span>
        </div>
        <div class="num_box num_box_c" v-else>
          +<span>{{ money }}</span
          >次
        </div>
      </div>
      <div class="icon">
        <img v-show="stats == 1 " src="../assets/c_1.png" alt="" />
        <img v-show="stats == 2 " src="../assets/c_2.png" alt="" />
        <img v-show="stats == 3 " src="../assets/c_3.png" alt="" />
        <img v-show="stats == 4" src="../assets/c_4.png" alt="" />
      </div>
    </div>
    <div class="coupon_info">
      <div class="coupon_info_t">使用规则</div>
      <div class="r_rule" v-if="type == 1"><div>{{ rule_l }}</div><div>{{rule_r}}</div> </div>
      <div class="r_timer" v-if="type == 1">
        <div>使用范围</div><div>金额满{{ usage_limit }}元 - {{ usage_limit_upper }}元的门票</div>
      </div>
      <div class="r_timer" v-else>
        <div>使用范围</div> <div>仅限次票{{ usage_limit }}次 -
        {{ usage_limit_upper }}次的门票可用</div>
      </div>
      <div class="r_timer">
       <div>有效期</div> <div>{{ timestampToDate(time_start) }}~{{ timestampToDate(time_end) }}</div>
      </div>
      <div v-show="stats == 2 && order_sn" class="r_timer">
        <div>使用订单</div> <div>{{ order_sn }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    usage_limit: Number,
    usage_limit_upper: Number,
    money: Number,
    type: Number,
    stats: Number,
    title: String,
    rule: String,
    time_start: Number,
    time_end: Number,
    order_sn: String,
  },
  data() {
    return {
        rule_l:'',
        rule_r:''
    };
  },
  created(){
    this.rule_l=this.rule.split(':')[0];
    this.rule_r=this.rule.split(':')[1];
  },
  methods: {
    timestampToDate(timestamp) {
      var date = new Date(timestamp * 1000); // 注意JS中的时间戳为秒数，因此需要将其乘以1000
      var year = date.getFullYear(); // 获取年份，四位数
      var month = ("0" + (date.getMonth() + 1)).slice(-2); // 获取月份，并用slice函数确保结果为两位数
      var day = ("0" + date.getDate()).slice(-2); // 获取日期，并用slice函数确保结果为两位数
      return `${year}-${month}-${day}`; // 返回格式为YYYY-MM-DD的日期字符串
    },
  },
};
</script>
<style scoped>
.item_box {
  width: 350rem;
  height: 115rem;
  background: #fef5cd;
  box-shadow: 0px 0.5px 0px 0px #ffffff inset;
  background-size: 100% 100%;
  margin: auto;
  box-sizing: border-box;
  margin-top: 10rem;
  display: flex;
  align-items: center;
  position: relative;
  padding: 15rem;
  box-sizing: border-box;
}
.item_box_c {
  background: #e6f0fa;
}
.coupon_info {
  width: 350rem;
  padding:25rem 15rem 17rem;
  box-sizing: border-box;
  background: #fff;
  margin: auto;
  border-radius: 18rem;
}
.item_logo {
  width: 80rem;
}
.item_logo img {
  width: 100%;
}
.item_box_l {
  width: 85rem;
}
.num_box {
  height: 26rem;
  font-weight: bold;
  text-align: left;
  color: #fb8300;
}
.num_box_c {
  color: #5396eb;
}
.num_box span {
  font-size: 26rem;
}
.num_info {
  color: #fff;
  text-align: center;
}
.item_box_r {
  margin-left: 25rem;
  font-size: 12rem;
  line-height: 16rem;
  color: #7f7f7f;
}
.r_title {
  color: #171746;
  font-weight: bold;
  font-size: 14rem;
  margin-bottom: 10rem;
}
.icon {
  width: 70rem;
  right: 10rem;
  top: 10rem;
  position: absolute;
}
.icon img {
  width: 100%;
}
.coupon_info_t {
  height: 16rem;
  font-size: 16rem;
  font-family: PingFangSC, PingFangSC-Semibold;
  font-weight: 600;
  text-align: left;
  color: #171746;
  line-height: 16rem;
  margin-bottom: 15rem;
}
.r_rule{
    font-size: 14rem;
    color: #171746;
    margin-bottom: 8rem;
    display: flex;

}
.r_rule>div:nth-child(1){
    color: #999AAE;
}   
.r_rule>div:nth-child(2){
    margin-left: auto;
}   
.r_timer{
    font-size: 14rem;
    color: #171746;
    margin-bottom: 8rem;
    display: flex;
}
.r_timer>div:nth-child(1){
    color: #999AAE;
}   
.r_timer>div:nth-child(2){
    margin-left: auto;
}  
</style>
